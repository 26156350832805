var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Space Deletion"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [_c("v-subheader", [_vm._v("SPACE NAME")])],
                1
              ),
              _c("v-col", { staticClass: "subtitle-2", attrs: { cols: "9" } }, [
                _vm._v(_vm._s(_vm.spaceName)),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [_c("v-subheader", [_vm._v("SPACE DESCRIPTION")])],
                1
              ),
              _c("v-col", { staticClass: "subtitle-2", attrs: { cols: "9" } }, [
                _vm._v(_vm._s(_vm.spaceDescription)),
              ]),
            ],
            1
          ),
          _c(
            "v-alert",
            {
              staticClass: "mt-5",
              attrs: {
                "max-width": "1000",
                outlined: "",
                color: "error",
                prominent: "",
                type: "warning",
              },
            },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Here is what happens when you delete a space"),
              ]),
              _c("ul", { staticClass: "mt-3" }, [
                _c("li", { staticClass: "subtitle-2" }, [
                  _vm._v(
                    "All files, data, and applications stored in all instances in this space will be deleted permanently."
                  ),
                ]),
                _c("li", { staticClass: "subtitle-2" }, [
                  _vm._v(
                    "All members of this space will lose their access and data."
                  ),
                ]),
              ]),
              _c("v-divider", {
                staticClass: "my-4 secondary",
                staticStyle: { opacity: "0.22" },
              }),
              _c("v-checkbox", {
                staticClass: "secondary--text",
                attrs: { color: "error", label: "I understand" },
                model: {
                  value: _vm.agreeBox,
                  callback: function ($$v) {
                    _vm.agreeBox = $$v
                  },
                  expression: "agreeBox",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-actions", [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column justify-center align-center w-100",
          },
          [
            _c("DeleteButton", {
              staticClass: "mb-3",
              attrs: {
                objectType: _vm.nuvolosObjectTypes.SPACE,
                objectName: _vm.spaceName,
                isAsyncDeletion: true,
                buttonName: "Delete space",
                cancelRoute: {
                  name: "snapshot-overview",
                  params: {
                    oid: _vm.$route.params.oid,
                    sid: _vm.$route.params.sid,
                    iid: _vm.$route.params.iid,
                    snid: _vm.$route.params.snid,
                  },
                },
                apiURL: "/spaces/" + this.$route.params.sid,
                id: parseInt(_vm.$route.params.sid, 10),
                higherLevelId: parseInt(_vm.$route.params.oid, 10),
                routeAfter: {
                  name: "home-dashboard",
                  params: { oid: _vm.$route.params.oid },
                },
                fetchString: "orgStore/fetchOrgSpaces",
                disabled: !_vm.agreeBox,
              },
              on: {
                error: function ($event) {
                  return _vm.errorMessage($event.error)
                },
              },
            }),
            _c(
              "v-alert",
              {
                staticClass: "mt-4",
                attrs: {
                  value: this.error,
                  color: "error",
                  icon: "warning",
                  outlined: "",
                },
              },
              [
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.errorContent)),
                  ]),
                  _c(
                    "span",
                    [
                      _vm._v(
                        " For more information on space creation issues, check the troubleshooting documentation "
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            small: "",
                            text: "",
                            color: "error",
                            href: "https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-delete-a-space",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" here ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }